<template>
  <div id="nav">
    <img id="img-logo" src="~@/assets/ic_launcher.png" />
    <span id="text-logo">知了清单</span>

    <div class="tool-right">
      <div class="div-product-menu" @click="goProduct()">其它产品</div>

      <div class="div-contact" @click="goContact()">联系我们</div>
    </div>
  </div>
  <div class="home">
    <div class="header-left">
      <div class="header-logo">
        <img src="~@/assets/ic_launcher.png" />
        <div>
          <p
            style="
              font-size: 32px;
              color: #666666;
              font-weight: 800;
              text-align: center;
              margin-top: 0px;
              margin-bottom: 0px;
            "
          >
            知了清单
          </p>
          <p
            style="
              font-size: 16px;
              color: #666666;
              font-weight: 400;
              text-align: left;
              margin-top: 8px;
              margin-bottom: 0px;
            "
          >
            待办(Todo)、日程计划、时间管理
          </p>
        </div>
      </div>
    </div>
    <div class="header-right">
      <div class="app-download-top">
        <div
          class="div-app-download-mobile"
          onclick="window.open('https://a.app.qq.com/o/simple.jsp?pkgname=com.denglin.zhiliao')"
        >
          <img src="~@/assets/ic_iphone.svg" />
          <p
            style="
              font-weight: 900;
              font-size: 16px;
              display: inline-block;
              color: #ffffff;
            "
          >
            iOS下载
          </p>
        </div>

        <div
          class="div-app-download-mobile"
          style="margin-left: 22px"
          onclick="window.open('https://a.app.qq.com/o/simple.jsp?pkgname=com.denglin.zhiliao')"
        >
          <img src="~@/assets/ic_android.svg" />
          <p
            style="
              font-weight: 900;
              font-size: 16px;
              display: inline-block;
              color: #ffffff;
            "
          >
            Android下载
          </p>
        </div>
      </div>
    </div>

    <div
      style="
        padding-top: 52px;
        padding-bottom: 52px;
        font-weight: 700;
        font-size: 30px;
      "
    >
      其它产品
    </div>
    <div class="div-product">
      <div class="product">
        <img class="img-product-logo" src="~@/assets/ic_app_jizhangya.png" />
        <p class="app-product-name">记账鸭-纯净记账本</p>
        <p class="app-product-desc">
          嗨~我是记账鸭，记账我是专一的，对你我是真心的，全身心专注为你记账，一切理财广告、社交干扰都被我拒之门外啦！
        </p>
        <a
          href="http://www.jizhangya.cn/"
          class="product-link"
          target="view_window"
          >了解更多 →</a
        >
      </div>

      <div class="product">
        <img class="img-product-logo" src="~@/assets/ic_app_moji.png" />
        <p class="app-product-name">墨记-日记和笔记</p>
        <p class="app-product-desc">
          墨记是一款精美排版的日记、笔记应用，拥有设计简洁、简单易用的特点，却同时拥有强大的功能！
        </p>
        <a
          href="http://www.moji01.com/"
          class="product-link"
          target="view_window"
          >了解更多 →</a
        >
      </div>

      <div class="product">
        <img class="img-product-logo" src="~@/assets/ic_app_wuji.png" />
        <p class="app-product-name">吾记-精美日记</p>
        <p class="app-product-desc">
          吾记，一款简约精致的日记本，加密记事、小情绪、生活小记、工作/学习记录，统统满足你！
        </p>
        <a href="http://wujiapp.com/" class="product-link" target="view_window"
          >了解更多 →</a
        >
      </div>

      <div class="product">
        <img class="img-product-logo" src="~@/assets/ic_app_mdays.png" />
        <p class="app-product-name">mDays-纪念日/倒数日/倒计时</p>
        <p class="app-product-desc">
          记录你生活中重要的日子，可以是纪念日、生日、高考倒计时、重要日程...
        </p>
        <a href="http://mdaysapp.com/" class="product-link" target="view_window"
          >了解更多 →</a
        >
      </div>

      <div class="product">
        <img class="img-product-logo" src="~@/assets/ic_app_moice.png" />
        <p class="app-product-name">Moice-录音标记的语音记事本</p>
        <p class="app-product-desc">
          Moice是一款高效录音标记的语音记事本。会议记录、课堂录音、语音日记、生活备忘、灵感捕捉、文艺创作…统统满足你！
        </p>
        <a href="http://moiceapp.com/" class="product-link" target="view_window"
          >了解更多 →</a
        >
      </div>
    </div>

    <div class="div-about">
      <div class="about">
        <p class="about-moji">联系我们</p>
        <p class="about-moji">安卓用户QQ群：763492104</p>
        <p class="about-moji">苹果用户QQ群：399805099</p>
        <p class="about-moji">联系邮箱：denglinkeji@163.com</p>
      </div>
    </div>
    <div class="div-record">
      <a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        class="moji-record"
        target="view_window"
        >粤ICP备19024063号</a
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "HomeMobile",
  components: {},
  methods: {
    goProduct() {
      let div = document.getElementsByClassName("div-product")[0];
      div.scrollIntoView();
    },

    goContact() {
      let div = document.getElementsByClassName("div-about")[0];
      div.scrollIntoView();
    },
  },
};
</script>

<style scoped>
#nav {
  padding: 0px 20px;
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  display: absolute;
  z-index: 200;
  border-bottom: 1px solid #f9f9f9;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

#img-logo {
  height: 50px;
  width: 50px;
}

#text-logo {
  font-weight: 900;
  font-size: 18px;
  color: #111111;
  margin-left: 6px;
  white-space: nowrap;
}

.tool-right {
  display: flex;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
}

.div-product-menu {
  padding-left: 12px;
  padding-right: 12px;
  height: 28px;
  width: fit-content;
  text-align: center;
  border-radius: 18px;
  line-height: 28px;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  white-space: nowrap;
  cursor: pointer;
}

.div-contact {
  padding-left: 12px;
  padding-right: 0px;
  height: 28px;
  width: fit-content;
  text-align: center;
  border-radius: 18px;
  line-height: 28px;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  white-space: nowrap;
  cursor: pointer;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.home {
  position: relative;
  height: calc(100% - 100px);
  width: 100%;
  overflow-y: scroll;
  overflow-x: auto;
  background: #fafafa;
}

.div-header {
  height: 500px;
  background: #fafafa;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-left {
  margin-top: 50px;
  width: 100%;
}

.header-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.header-logo img {
  height: 80px;
  width: 80px;
  border-radius: 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.header-logo div {
  margin-left: 20px;
  text-align: center;
}

.header-right {
  width: 100%;
}

.app-download-top {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 36px;
  flex-direction: row;
  justify-content: center;
}

.div-app-download {
  height: 70px;
  width: 100%;
  display: flex;
  text-align: center;
  border-radius: 12px;
  background-color: #eeeeee;
}

.div-app-download img {
  margin: 20px;
  height: 30px;
  width: 30px;
}

.div-app-download-mobile {
  height: 70px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #39d2f9;
  cursor: pointer;
}

.div-app-download-mobile img {
  margin: 16px;
  height: 24px;
  width: 24px;
}

.div-func {
  background: #ffffff;
  overflow-y: auto;
  white-space: nowrap; /*不会换行，会在在同一行上继续*/
  overflow-x: scroll;
  padding-bottom: 52px;
}

/*自定义滚动条的伪对象选择器, CSS 可以隐藏滚动条*/
.div-func ::-webkit-scrollbar {
  display: none;
}

.img-func {
  margin-left: 22px;
  margin-right: 22px;
  height: 360px;
}

.div-download {
  border-bottom: 1px solid #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  background: #ffffff;
}

.div-logo-parent {
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.div-logo {
  height: 100%;
  flex-grow: 2;
  text-align: end;
  z-index: 100%;
}

.img-logo {
  vertical-align: middle;
  width: 50%;
}

.div-demo {
  z-index: 100;
  flex-grow: 8;
}

.img-demo {
  margin-top: 60px;
  margin-bottom: 60px;
  width: 80%;
}

.img-bg {
  position: absolute;
  right: 0;
  margin: 0 auto;
  top: 20px;
  width: 100%;
}

.div-download-app {
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  /* 自动换行 */
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.div-app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 140px;
  margin-bottom: 42px;
  cursor: pointer;
  margin-right: 12px;
  background: #ffffff;
}

.app-code {
  position: relative;
  height: 130px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-logo {
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 65px;
  background-color: #666666;
}

.img-code {
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;
  height: 130px;
  width: 130px;
  opacity: 0;
  transition: 1s;
  overflow: hidden;
}

.app-logo img {
  height: 36px;
  width: 36px;
}

.app-code:hover .mobile-app {
  opacity: 0;
  transition: 1s;
  overflow: hidden;
}

.app-code:hover .img-code {
  opacity: 1;
  transition: 1s;
  overflow: visible;
}

.app-name {
  color: #333333;
  font-weight: 700;
  font-size: 20px;
  margin-top: 0px;
}

.app-download-button {
  height: 36px;
  width: 126px;
  background-color: #666666;
  color: white;
  border-radius: 6px;
  font-weight: 700;
  line-height: 36px;
  text-decoration: none;
}

.div-product {
  height: auto;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 40px;

  display: flex;
  padding-bottom: 12px;
  flex-wrap: wrap;
  /* 自动换行 */
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #f9f9f9;
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: left;
  justify-items: left;
  padding-bottom: 32px;
  width: 100%;
  margin-bottom: 22px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #f9f9f9;
  border-bottom: 1px solid #f9f9f9;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
}

.img-product-logo {
  height: 60px;
  width: 60px;
  margin-top: 32px;
  margin-left: 42px;
  border-radius: 50px;
}

.app-product-name {
  color: #333333;
  font-weight: 700;
  font-size: 22px;
  margin-top: 22px;
  margin-left: 42px;
  margin-right: 42px;
  margin-bottom: 0px;
  text-align: left;
}

.app-product-desc {
  color: #333333;
  font-size: 16px;
  margin-top: 22px;
  margin-left: 42px;
  margin-right: 42px;
  margin-bottom: 0px;
  line-height: 1.6;
  text-align: left;
}

.product-link {
  color: #46aaf7;
  font-size: 18px;
  font-weight: 500;
  margin-top: 22px;
  text-decoration: none;
  text-align: left;
  margin-left: 42px;
}

.div-about {
  height: auto;
  width: calc(100% - 0px);
  display: flex;
  padding-top: 32px;
  padding-bottom: 32px;
  flex-wrap: wrap;
  /* 自动换行 */
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  padding: 22px;
  margin-bottom: 22px;
}

.about-moji {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 22px;
  text-align: left;
}

.div-record {
  background: #ffffff;
  padding-bottom: 22px;
}
.moji-record {
  color: #333333;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  text-align: left;
}
</style>
